import React from 'react';
import { IconButton, Tooltip, SvgIcon } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

export const SystemTickets = ({ ...rest }) => {
    return (
        <Tooltip title="Help Desk">
            <IconButton
                color="inherit"
                component="a"
                href={process.env.REACT_APP_HELP_DESK_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                <SvgIcon fontSize="medium">
                    <ConfirmationNumberIcon style={{ color: '#f44336' }} />
                </SvgIcon>
            </IconButton>
        </Tooltip>
    );
};
